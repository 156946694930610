import { useCurrentUser } from '../../graphql/hooks'
import { profileCanBeEventParticipant } from '../profileSelector/profileTypeCanBeParticipant'

export function useEventInvitations() {
  const { user } = useCurrentUser()

  if (!user) {
    return []
  }

  if (profileCanBeEventParticipant(user.selectedProfile)) {
    return user.selectedProfile?.eventInvitations ?? []
  }

  return []
}
